<template>
  <div class="price-form">
    <div class="vx-row mb-6">
      <div class="vx-col">
        <div class="w-1/1">
          <label class="vs-input--label">{{ $t("shops.amount_info") }}</label>
          <div>
            <vs-input-number
              class="w-1/1 float-left"
              v-model="value.amount"
              v-validate="'required'"
              name="amount"
              :danger="errorAmount && errors.has('amount')"
              :danger-text="$t('shops.errors.amount_is_required')"
            />

            <div class="float-left mt-2 ml-2">{{ currency }}</div>
          </div>
        </div>
      </div>

      <div class="vx-col w-1/4">
        <label class="vs-input--label">{{ $t("shops.product") }}</label>
        <v-select
          v-model="value.product.name"
          :options="productList"
          @input="selectProduct"
          class="w-full select-auto"
          :class="{ 'danger-border-select': errorProductId && errors.has('product_id') }"
          :value="value.product.id"
          name="product_id"
          :placeholder="$t('shops.select_a_product')"
          v-validate="'required'"
          :danger-text="$t('shops.errors.product_is_required')"
          :danger="errorProductId && errors.has('product_id')"
        />
      </div>

      <div class="vx-col w-1/4">
        <template v-if="isProductSelected">
          <label class="vs-input--label">{{ $t("shops.period") }}</label>
          <v-select
            v-model="value.period.name"
            :options="periodList"
            @input="selectPeriod"
            class="w-full select-auto"
            :value="value.period.id"
            name="period_id"
            :placeholder="$t('shops.no_periods')"
          />
        </template>
      </div>

      <div class="vx-col w-1/4">
        <template v-if="isProductSelected">
          <label class="vs-input--label">{{ $t("shops.participant") }}</label>
          <v-select
            v-model="value.participant.name"
            :options="participantList"
            @input="selectParticipant"
            class="w-full select-auto"
            :value="value.participant_id"
            name="participant_id"
            :placeholder="$t('shops.all_participants')"
          />
        </template>
      </div>

      <div class="vx-col w-1/3">
        <vs-button @click="validateAndSubmit(value)" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import i18n from "@/i18n";

const defaultPrice = () => ({
  id: 0,
  product_id: 0,
  participant_id: 0,
  period_id: 0,
  product: { id: 0, name: "" },
  period: { id: 0, name: "" },
  participant: { id: 0, name: "" },
  amount: 0,
});

export default {
  name: "PriceFrom",

  props: {
    value: { type: Object, default: () => defaultPrice() },
  },

  mixins: [notifications],

  data() {
    return {
      errorAmount: false,
      errorProductId: false,
      currency: "",
      productCatalogId: 0,
      isProductSelected: false,
    };
  },

  computed: {
    ...mapGetters("shops/products", {
      productListGetter: "getAll",
      getProductById: "getById",
    }),

    ...mapGetters("shops/participants", {
      participantListFilter: "getByFilter",
    }),

    ...mapGetters("shops/periods", { periodListFilter: "getByFilter" }),

    productList() {
      return this.productListGetter.map(this.mapToViewModel);
    },

    periodList() {
      return this.periodListFilter(
        (period) => period.catalog_id === this.productCatalogId
      ).map(this.mapToViewModel);
    },

    participantList() {
      return this.participantListFilter((participant) =>
        participant.catalogs.every(
          (catalog) => catalog.catalog_id === this.productCatalogId
        )
      ).map(this.mapToViewModel);
    },
  },

  methods: {
    ...mapActions("shops/products", { loadProductsList: "load" }),
    ...mapActions("shops/participants", { loadParticipantsList: "load" }),
    ...mapActions("shops/periods", { loadPeriodsList: "load" }),

    validateAndSubmit(value) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("submit", value);
        } else {
          this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.price_validation_failed"));
          if (!this.value.amount) this.errorAmount = true;
          if (!this.value.product_id) this.errorProductId = true;
        }
      });
    },

    mapToViewModel(model) {
      return {
        label: model.name,
        value: model.id
      }
    },

    selectProduct(option) {
      this.value.product_id = option.value;
      let selectedProduct = this.getProductById(this.value.product_id);

      if (selectedProduct) {
        try {
          this.productCatalogId = selectedProduct.catalogs[0].catalog_id || 0;
        } catch (e) {
          console.warn(e);
          this.productCatalogId = 0;
        }
      }

      this.isProductSelected = true;
    },

    selectParticipant(option) {
      this.value.participant_id = option === null ? null : option.value;
    },

    selectPeriod(option) {
      this.value.period_id = option === null ? null : option.value;
    },
  },

  created() {
    /*this.currency = this.$store.getters["shops/resorts/getById"](
      parseInt(this.$store.getters["shops/resorts/resort"].id)
    ).currency;*/
    this.currency = this.$store.getters["shops/resorts/resort"].currency;
    this.loadProductsList({ page_size: 1000 });
    this.loadParticipantsList({ page_size: 1000 });
    this.loadPeriodsList({ page_size: 1000, marketing: false });
    this.value.participant_id =
      this.value.participant.name != "" ? this.value.participant.id : null;
    this.value.period_id =
      this.value.period.name != "" ? this.value.period.id : null;
    this.value.product_id =
      this.value.product.name != "" ? this.value.product.id : null;
  },

  beforeMount() {
    // Show all fields if the form is used to edit an existing model
    if (this.value.product.id !== 0) {
      this.selectProduct({ value: this.value.product.id });
    }
  },
};
</script>

<style>
</style>
