<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <vs-button v-if="showForm" @click="onCreate">{{ $t("shops.back") }}</vs-button>
      <transition name="fade" mode="out-in">
        <div class="mb-base" v-if="!showForm">
          <data-table
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove($event, 'shops.prices')"
            @update="onUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
            @date-range-search="onDateRangeSearch"
            @date-range-clear="onDateRangeClear"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <price-form v-model="formData" @submit="onPriceFormSubmit" />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable";
import PriceForm from "@/modules/Shops/Pages/forms/PriceForm.vue";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n";

export default {
  name: "Prices",

  mixins: [notifications, table_functions],

  components: {
    DataTable,
    PriceForm
  },

  props: {
    standalone: { type: Boolean, default: true },
    onlyForm: { type: Boolean, default: false }
  },

  data() {
    return {
      showForm: false,
      formData: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/prices", {
      pricesList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      priceById: "getById"
    }),

    tableData() {
      if(this.currentPage !== 1) {
        this.checkPage()
      }
      if(Array.isArray(this.pricesList)) {
        return this.pricesList.map(this.priceToTableData);
      } else {
        return Object.values(this.pricesList).map(this.priceToTableData);
      }
    },

    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          dateRange: true,
        },
      };
    },
  },

  methods: {
    ...mapActions("shops/prices", {
      loadData: "load",
      removeData: "remove",
      updatePrice: "update",
      createPrice: "create"
    }),

    checkPage() {
      if(Object.keys(this.pricesList).length === 0) {
        this.loadData({page: 1, page_size: this.perPage });
      }
    },

    priceToTableData(price) {
      return {
        id: price.id,
        amount: price.amount,
        product: price.product ? price.product.name : "-",
        period: price.period ? price.period.name : "-",
        participant: price.participant ? price.participant.name : "-",
        created_at: price.created_at,
        updated_at: price.updated_at
      };
    },

    async onPriceFormSubmit(price) {
      this.$vs.loading();
      try {
        if (price.id === 0) {
          await this.createPrice(price);
          this.price = Object.assign({}, this.price, price);
          this.notifySuccess(
            i18n.t("shops.prices.title"),
            `${i18n.t('shops.price')} ${i18n.t('shops.has_been_created_successfully')}`
          );
        } else {
          await this.updatePrice(price);
          this.notifySuccess(
            i18n.t("shops.updated"),
            `${i18n.t('shops.price')} ${i18n.t('shops.has_been_updated_successfully')}`
          );
        }
        this.formData = this.default();
        this.showForm = false;
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_price"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async onUpdate(price) {
      this.formData = Object.assign({}, this.formData, this.priceById(price.id));

      this.formData.product == undefined
        ? (this.formData = Object.assign({}, this.formData, {
            product: { name: "", id: "" }
          }))
        : {};
      this.formData.period == undefined
        ? (this.formData = Object.assign({}, this.formData, {
            period: { name: "", id: "" }
          }))
        : {};
      this.formData.participant == undefined
        ? (this.formData = Object.assign({}, this.formData, {
            participant: { name: "", id: "" }
          }))
        : {};

      this.showForm = true;
    },

    default() {
      return {
        id: 0,
        product_id: 0,
        participant_id: 0,
        period_id: 0,
        product: { id: 0, name: "" },
        period: { id: 0, name: "" },
        participant: { id: 0, name: "" },
        amount: 0,
      }
    }
  },

  created() {
    this.$store.commit("shops/prices/RESET_RESOURCE");
  }
};
</script>

<style>
</style>
