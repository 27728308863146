var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "price-form" }, [
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c("div", { staticClass: "vx-col" }, [
        _c("div", { staticClass: "w-1/1" }, [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.amount_info")))
          ]),
          _c(
            "div",
            [
              _c("vs-input-number", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "w-1/1 float-left",
                attrs: {
                  name: "amount",
                  danger: _vm.errorAmount && _vm.errors.has("amount"),
                  "danger-text": _vm.$t("shops.errors.amount_is_required")
                },
                model: {
                  value: _vm.value.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "amount", $$v)
                  },
                  expression: "value.amount"
                }
              }),
              _c("div", { staticClass: "float-left mt-2 ml-2" }, [
                _vm._v(_vm._s(_vm.currency))
              ])
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-1/4" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.product")))
          ]),
          _c("v-select", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full select-auto",
            class: {
              "danger-border-select":
                _vm.errorProductId && _vm.errors.has("product_id")
            },
            attrs: {
              options: _vm.productList,
              value: _vm.value.product.id,
              name: "product_id",
              placeholder: _vm.$t("shops.select_a_product"),
              "danger-text": _vm.$t("shops.errors.product_is_required"),
              danger: _vm.errorProductId && _vm.errors.has("product_id")
            },
            on: { input: _vm.selectProduct },
            model: {
              value: _vm.value.product.name,
              callback: function($$v) {
                _vm.$set(_vm.value.product, "name", $$v)
              },
              expression: "value.product.name"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/4" },
        [
          _vm.isProductSelected
            ? [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(_vm._s(_vm.$t("shops.period")))
                ]),
                _c("v-select", {
                  staticClass: "w-full select-auto",
                  attrs: {
                    options: _vm.periodList,
                    value: _vm.value.period.id,
                    name: "period_id",
                    placeholder: _vm.$t("shops.no_periods")
                  },
                  on: { input: _vm.selectPeriod },
                  model: {
                    value: _vm.value.period.name,
                    callback: function($$v) {
                      _vm.$set(_vm.value.period, "name", $$v)
                    },
                    expression: "value.period.name"
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/4" },
        [
          _vm.isProductSelected
            ? [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(_vm._s(_vm.$t("shops.participant")))
                ]),
                _c("v-select", {
                  staticClass: "w-full select-auto",
                  attrs: {
                    options: _vm.participantList,
                    value: _vm.value.participant_id,
                    name: "participant_id",
                    placeholder: _vm.$t("shops.all_participants")
                  },
                  on: { input: _vm.selectParticipant },
                  model: {
                    value: _vm.value.participant.name,
                    callback: function($$v) {
                      _vm.$set(_vm.value.participant, "name", $$v)
                    },
                    expression: "value.participant.name"
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-base",
              attrs: { type: "filled" },
              on: {
                click: function($event) {
                  return _vm.validateAndSubmit(_vm.value)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("shops.save")))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }