var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-base no-shadow" },
        [
          _vm.showForm
            ? _c("vs-button", { on: { click: _vm.onCreate } }, [
                _vm._v(_vm._s(_vm.$t("shops.back")))
              ])
            : _vm._e(),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            !_vm.showForm
              ? _c(
                  "div",
                  { staticClass: "mb-base" },
                  [
                    _c("data-table", {
                      attrs: { data: _vm.tableData, options: _vm.tableOptions },
                      on: {
                        create: _vm.onCreate,
                        remove: function($event) {
                          return _vm.onRemove($event, "shops.prices")
                        },
                        update: _vm.onUpdate,
                        sort: _vm.onSort,
                        search: _vm.onSearch,
                        "change-page": _vm.onTablePageChange,
                        "per-page-change": _vm.onPerPageChange,
                        "date-range-search": _vm.onDateRangeSearch,
                        "date-range-clear": _vm.onDateRangeClear
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.showForm
              ? _c(
                  "div",
                  { staticClass: "mb-base mt-10" },
                  [
                    _c("price-form", {
                      on: { submit: _vm.onPriceFormSubmit },
                      model: {
                        value: _vm.formData,
                        callback: function($$v) {
                          _vm.formData = $$v
                        },
                        expression: "formData"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }